import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PostCard from "../components/PostCard";
import TagMenu from "../components/TagMenu";
import TagJsonLd from "../components/json/TagJsonLd";
import styled from "styled-components";

const Heading = styled.h1`
  margin: 0.5em 0 0.8em;
  font-size: 32px;
  color: #fff;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 1px;
`;

class TagTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const posts = data.allMarkdownRemark.edges;
    const { location } = this.props;
    const tagSlug = pageContext.tag;

    return (
      <Layout location={this.props.location} title={tagSlug}>
        <SEO title={tagSlug} />
        <TagJsonLd tagSlug={tagSlug} />
        <TagMenu location={location} />
        <Heading>{tagSlug}</Heading>
        {posts.map(({ node }) => {
          return <PostCard key={node.fields.slug} node={node} />;
        })}
      </Layout>
    );
  }
}

export default TagTemplate;

export const pageQuery = graphql`
  query BlogPostByTag($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY.MM.DD")
            title
            emoji
            tags
          }
        }
      }
    }
  }
`;
